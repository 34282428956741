.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background-color: #fff;
  padding: 1rem;
  border-radius: 8px;
  max-width: 600px;
  text-align: center;
  margin: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modalContent .tuto {
  overflow-y: scroll;
  overflow-x: hidden;
  flex: 1;
}

.modal-cookies-content p,
.modal-cookies-content h5 {
  text-align: start;
  color: black;
}

.font-bold {
  font-weight: bold;
}

.modal-cookies-content img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}