
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: "Jost", sans-serif;
  font-weight: 500;
  line-height: 1.2;
  /* color: #262B47; */
}

.text-primary-dark {
  color: var(--primary-dark-color);
}

.text-error-color {
  color: var(--error-color);
}

.bg-secondary-light {
  background: var(--secondary-light-color);
}

.form-search-default {
  max-width: 450px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 6px;
  box-shadow: 0 3px 3px var(--box-shadow-primary-color);
  border: none;
  min-width: 250px;
  min-height: 38px;
  display: flex;
  padding: 3px;
}

.form-search-default input {
  background: transparent;
  flex: 1;
  border: none;
  width: 100%;
  padding-left: 8px;
  color: var(--primary-dark-color);
  z-index: 10;
}

.form-search-default input:focus {
  outline: transparent;
}

.form-search-default input::placeholder {
  color: var(--primary-dark-color);
  opacity: 0.5;
}

.form-search-default button {
  width: 32px;
  height: 32px;
  background: var(--primary-color);
  color: white;
  border: none;
  padding: 4px;
  border-radius: 6px;
  opacity: 0.85;
}

ul {
  list-style: none;
  padding: 0;
}

.button-default {
  background: var(--primary-color);
  color: white;
  border: none;
  text-decoration: none;
  padding: 6px 12px;
  border-radius: 6px;
  box-shadow: 0 3px 3px var(--box-shadow-primary-color);
  transition-duration: 400ms;
}

.button-default.button-reversed {
  font-weight: 600;
  color: var(--primary-color);
  background-color: white;
  padding: 2px 8px;
  border: 2px solid var(--primary-color);
}

.button-default:hover {
  background: var(--primary-dark-color);
  color: white;
}

.button-default.button-reversed:hover {
  border-color: var(--primary-dark-color);
}

.button-warning {
  background: var(--primary-color);
  color: white;
  border: none;
  padding: 4px 12px;
  border-radius: 6px;
}

.button-default-icon {
  background: transparent;
  border: none;
  font-size: 0.4rem;
  padding: 8px;
  border-radius: 4px;
  transition-duration: 400ms;
}

.button-default-icon:hover {
  background: var(--secondary-color);
}

.card-itemt {
  background: var(--placeholder-color);
}
/* Radio button */
/* Hide the default radio button */
input[type="radio"] {
  display: none;
}

/* Create a custom radio button */
.custom-radio-label {
  display: inline-block;
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  font-size: 1.2rem;
  
  user-select: none;
}

/* Create the custom radio button */
.custom-radio-label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  background: white;
}

/* Custom radio button checked state */
input[type="radio"]:checked + .custom-radio-label::before {
  background: var(--primary-color);
  border-color: var(--primary-color);
}

input[type="radio"]:checked + .custom-radio-label {
  font-weight: 500;
  color: var(--primary-color);
}

/* Custom radio button inner circle */
input[type="radio"]:checked + .custom-radio-label::after {
  content: "";
  position: absolute;
  left: 6px;
  top: 10px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.button-default {
  background: var(--primary-color);
  color: white;
  padding: 4px 12px;
}

.button-default.reversed-color {
  background: white;
  color: var(--primary-color);
  font-weight: 500;
  border: 3px solid var(--primary-color);
}

.button-default.reversed-color:hover {
  background: var(--primary-color);
  color: white;
}

.modal-default {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99;
}

.modal-tender-wrapper {
  max-width: 700px;
  background: white;
  border-radius: 4px;
  margin-top: 16px;
  margin-bottom: 16px;
  position: relative;
}

.modal-tender-wrapper .tender {
  border-top: none;
}

.modal-tender-wrapper .close {
  position: absolute;
  top: 8px;
  right: 8px;
  opacity: 0.8;
}
.modal-tender-wrapper .close:hover {
  opacity: 1;
}

.notification {
  position: fixed;
  top: 0;
  right: 0;
  padding: 12px;
  background: white;
  border-radius: 6px;
  box-shadow: 0 3px 3px var(--box-shadow-primary-color);
  margin: 12px;
  z-index: 100;
  color: var(--primary-color)
}

.custom-modal {
  display: block;
  opacity: 1;
  background: #0000008f;
}

.pop-testing {
  position: fixed;
  top: 55px;
  right: 12px;
  z-index: 99;
  padding: 4px 6px;
  font-weight: 600;
  background: white;
  border-radius: 4px;
  color: var(--primary-dark-color);
  border: 1px solid var(--primary-dark-color);
}
