body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Heebo", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #919294;
  background: transparent;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  --primary-dark-color: #5D3E30;
  --primary-color: #803D3B;
  --secondary-color: #F1E1CE;
  --secondary-light-color: #FAF4EC;
  --placeholder-color: #EBE0E0;
  --box-shadow-primary-color: rgba(128, 61, 59, 0.16);
  --error-color: #ff7a74;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
