.hero {
  color: var(--primary-dark-color);
  min-height: 80vh;
  background: var(--secondary-color);
  padding: 100px 38px 50px 38px;
  overflow: hidden;
  position: relative;
}

.hero .bg-img img {
  z-index: 2;
  content: '';
  width: 300px;
  height: 300px;
  bottom: -40px;
  right: 0;
  position: absolute;
  /*background-image: url('./imgs/hero-img.png');*/
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.1; /* Adjust opacity as needed */
}

.hero-texts {
  max-width: 700px;
}

.hero-texts h4 {
  color: var(--primary-dark-color);
  font-size: 1.0rem;
  letter-spacing: 4px;
}

.hero-texts h1 {
  color: var(--primary-dark-color);
  font-weight: 700;
  font-size: 2.4rem;
}

.hero-texts p {
  padding-top: 8px;
  padding-right: 30px;
  padding-bottom: 8px;
}

@media (min-width: 768px) {
  .hero-texts h1 {
    font-size: 3rem;
  }

  .hero-texts h4 {
    font-size: 1.4rem;
  }
  
}

@media (min-width: 1118px) {
  .hero .bg-img img {
    z-index: 2;
    content: '';
    width: 40%;
    height: 80%;
    min-width: 350px;
    min-height: 350px;
    bottom: 0;
    right: 0;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1; /* Adjust opacity as needed */
  }
}
