body:has(.auth) {
  height: 100vh;
  background: var(--secondary-light-color);
}

section.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 70vh;
}