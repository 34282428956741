/* Princing */
.pricing .nav-pills {
  border: none !important;
  overflow: hidden;
  border-radius: 0 !important;
}

.pricing .nav-pills .nav-item {
  border: 2px solid var(--primary-color);
  border-radius: 1rem !important;
  overflow: hidden;
  margin-bottom: 8px;
}

@media (min-width: 1010px) {
  .pricing .nav-pills {
    border: 2px solid var(--primary-color) !important;
    overflow: hidden;
    border-radius: 50rem !important;
  }
  .pricing .nav-pills .nav-item {
    border: none;
    border-radius: 0 !important;
    overflow: hidden;
    margin-bottom: 0;
  }

  .pricing .nav-item .nav-link.active {
    background: var(--primary-color) !important;
    color: white;
  }
}
.pricing .nav-item .nav-link {
  color: var(--primary-color);
  font-weight: 500;
  border-radius: 0 !important;
}

.pricing .nav-item .nav-link.active {
  background: var(--primary-color) !important;
  border-radius: 1px;
  color: white;
}
.pricing h2 {
  font-size: 2rem;
  color: var(--primary-dark-color);
}

.pricing h3,
.pricing h4,
.pricing h5 {
  color: var(--primary-color);
}

.pricing h3 {
  font-size: 2.2rem;
}

.card-item {
  color: var(--primary-color);
  background: #f1e1ce62;
  border: 1px solid var(--secondary-color);
}

.nav-pills {
  display: none;
}
