
h1 {

  color: var(--primary-color) !important;
}

button.go-step-1 {
  color: var(--primary-color) !important;
  margin-bottom: 24px;
  background: transparent;
  border: none;
}

input[type="radio"] {
  width: 20px;
  height: 20px;
  color: var(--primary-dark-color);
}

h3 {
  color: var(--primary-color) !important;
}

p {
  color: var(--primary-dark-color);
}

.footer {
  margin-top: 30px;
}

.order-total {
  padding-top: 18px;
  font-size: 1.5rem;
}

.currency {
  font-size: 1rem;
}

#discount-input + button[type="button"] {
  width: auto !important;
}

.payement .form-search-default {
  min-width: 350px;
}

.payement {
  padding-top: 80px;
}

.payement .nav-pill {
  display: none !important;
}