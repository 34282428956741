.tenders-search-wrapper {
  background: var(--secondary-light-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tenders-search-wrapper h1 {
  color: var(--primary-color);
  font-size: 1.6rem;
}

.tender-section {
  max-width: 1100px;
  /*max-width: 700px;*/
  width: 100%;
  min-height: 150px;
  background: white;
  padding: 20px 40px 60px 40px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.tender-search p {
  color: var(--primary-color);
}

.tender-search input[type="checkbox"] {
  width: 15px;
  color: var(--primary-color);
  accent-color: var(--primary-color);
  margin-top: 6px;
}

.tender-search input[type="checkbox"]:checked + label {
  color: var(--primary-color);
  max-width: 150px;
} 

.tender-search input[type="checkbox"]:checked + label[for="office-tenders"] {
  max-width: 200px;
}

.tender-search .form-search-default {
  max-width: unset !important;
  border: 0.4px solid var(--secondary-color);
}

.tenders-result.tender-section {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 0;
  margin-bottom: 16px;
}

.tenders-result.tender-section .tender-result-header {
  display: flex;
  justify-content: space-between;
  padding: 16px 40px;
}

.tenders-result.tender-section .tender-result-header button {
  background: transparent;
  border: none;
  color: var(--primary-color);

}

.tenders-result.tender-section .tender-result-header button:hover {
  text-decoration: underline;
}

.tenders-result > p {
  color: var(--primary-color);
  padding: 16px 40px 16px 40px;
}

.tender {
  border-top: 1px solid var(--primary-color);
  padding: 24px;
}

.tender[data-viewed="true"] {
  background: #dfdfdf94;
}

.tender h2 {
  font-size: 1.2rem;
}

.tender-title-icon img{
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-bottom: 3px;
  margin-right: 4px;
}

.tender h2,p {
  margin-bottom: 0 !important;
  color: var(--primary-dark-color);
}

.tender-icon {
  width: 68px;
  height: 68px;
  padding: 16px;
  margin-top: 8px;
  border-radius: 12px;
  background: #FAF4EC;
}

.tender-title {
  padding: 8px 12px;
  color: var(--primary-dark-color);
}

.tender-title svg {
  margin-right: 8px;
}

.tender-description {
  padding-top: 8px;
}

.buyer-name-wrapper {
  padding-top: 16px;
}

.buyer-name {
  font-weight: 500;
}

.tender .tags {
  gap: 6px;
  padding-top: 8px;
  flex-wrap: wrap;
}

.tags .tag {
  flex-shrink: 0;
  padding: 2px 8px;
  border-radius: 4px;
  color: var(--primary-dark-color);
  background: var(--secondary-color);
}

.tag button {
  background: transparent;
  border: none;
  color: var(--primary-dark-color);
  transition-duration: 400ms;
}

.tag button:hover {
  color: white;
}

.info-wrapper {
  gap: 4px;
  padding-top: 8px;
  justify-content: space-between;
}

.tender-buttons {
  margin-top: 8px;
}

.tender-buttons button.modal-only {
  display: none;
}

.pagination {
  width: 100%;
  max-width: 700px;
  margin-bottom: 16px;
}

.pagination button,
.pagination div {
  background: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  color: var(--primary-dark-color);
  transition-duration: 400ms;
}

.pagination button:hover {
  background: var(--primary-color);
  color: white;
}

.modal-tender-wrapper .tender .tender-buttons {
  margin-top: 24px;
}

.modal-tender-wrapper .tender-buttons button.show {
  display: none;
}

.modal-tender-wrapper .tender-buttons button.modal-only {
  display: block;
}

.section-comments {
  padding: 8px 0 16px 0;
}

.section-comments p {
  padding-left: 24px;
  padding-right: 24px;
}
