.list-activities {
  padding-top: 54px;
}

.activity {
  width: 280px;
  height: 100%;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 18px;
  color: var(--primary-dark-color);
  border: 1px solid var(--primary-color);
  overflow: hidden;
}

.activity .header {
  display: flex;
}

.activity .header .img-wrapper {
  width: 64px;
  height: 64px;
  border-radius: 16px;
  background: var(--placeholder-color);
}

.activity .header .details {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 4px;
  padding-left: 8px;
  gap: 6px;
}

.activity .header .details p {
  display: flex;
  font-size: 0.688rem;
  padding-right: 6px;
}

.activity .header .details .svg-icon {
  flex-shrink: 0;
  width: 19px;
  height: 19px;
  border-radius: 4px;
  background: var(--placeholder-color);
  margin-right: 6px;
}

.activity .content {
  padding-top: 14px;
}

.activity .content h3 {
  font-size: 1.188rem;
  font-weight: 500;
  color: var(--primary-color);
}

.activity .content p {
  font-size: 0.688rem;
}