.list-activities-search {
  display: flex;
}

.activity-search {
  display: flex; /* Keeps checkbox and label inline */
  align-items: center; /* Vertically aligns the items in the center */
}

.search-bar + button {
  width: 150px;
}

.summary-search p:first-child {
  font-weight: 500;
}

.cannot-see-wrapper {
  width: 100%;
  height: 200px;
  position: relative;
  overflow: hidden;
}

.cannot-see-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.3); /* Light gray with some transparency */
  backdrop-filter: blur(10px); /* Blur effect */
}

.country-small-text {
  font-size: 13px;
}