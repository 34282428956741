  /* General Styles */
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary-color);
    padding: 1px 20px;
    color: white;
    position: fixed;
    width: 100%;
    z-index: 99;
  }

  .logo {
    height: 40px;
    display: flex;
    align-items: center;
    text-decoration: none !important;
  }
  
  .logo img {
    width: 40px;
  }
  
  .logo h1 {
    width: 200px;
    font-size: 1.6rem;
    font-weight: 500;
    color: white !important;
    margin-bottom: 0;
    margin-left: 6px;
  }
  

  .nav-menu {
    z-index: 10;
    display: flex;
    align-items: center;
  }

  .nav-menu .active-link {
    text-decoration: underline;
  }

  .nav-menu button {
    color: white;
    border: none;
    background: transparent;
  }
  
  .nav-menu button,
  .nav-menu a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .nav-menu button:hover,
  .nav-menu a:hover {
    text-decoration: underline;
  }
  
  .nav-menu button.btn-menu {
    display: none; /* Hidden by default */
    background-color: transparent;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
  }
  
  .nav-menu ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .nav-menu ul li {
    display: flex;
  }
  
  .nav-menu ul li a {
    flex: 1;
    padding: 16px 16px;
    color: white;
    text-decoration: none;
    transition-duration: 200ms;
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {

    header {
      padding: 12px 20px;
    }
    
    .nav-menu button.btn-menu{
      display: block !important;
    }
  
    .nav-menu ul {
      display: none; /* Hidden by default on mobile */
      flex-direction: column;
      position: absolute;
      top: 42px;
      padding: 0px 2px 2px 2px;
      right: 20px;
      background-color: var(--primary-color);
    }
  
    .nav-menu ul.show {
      display: flex;
    }
  
    .nav-menu ul li:hover a {
      background: white;
      color: var(--primary-color);
    }
  }