.hero-search-bar button {
  z-index: 10;
}

/* About */
.about .sections {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 30px;
}

.about .sections:nth-child(even) {
  flex-direction: row-reverse;
  flex-wrap: wrap;
}
.about .sections .img-container,
.about .sections .content {
  flex: 1;
  min-width: 250px;
  margin: 0 38px;
}

.about .sections .img-container .img-wrapper {
  min-height: 240px;
  margin-top: 24px;
  margin-bottom: 8px;
  border-radius: 8px;
}

.about .sections .img-container img {
  width: 100%;
  height: 100%;
}


.about .sections .content h2 {
  color: var(--primary-dark-color);
  font-size: 2rem;
}

.about .sections .content p {
  color: var(--primary-dark-color);
}

.about .sections .content button {
  margin-top: 16px;
}

@media (min-width: 650px){
  .about .sections .img-container .img-wrapper {
    margin-top: 0;
    margin-bottom: 0;
  }
}
/* End About */

