.country-activty {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  padding: 48px 38px;
}

.country-activty h2 {
  text-align: center;
  color: var(--primary-dark-color);
}

.list-countries, .list-activities {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding-top: 24px;
  justify-content: center;
  margin: auto;
}

.country {
  display: flex;
}

.country .svg-flag {
  width: 48px;
  height: 28px;
  border-radius: 5px;
  margin-right: 6px;
  overflow: hidden;
  background: var(--placeholder-color);
}

.country .svg-flag img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.country .content p.name {
  max-width: 120px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 3px;
}

.country .content p.tag {
  font-weight: 500;
  line-height: 16px;
  font-size: 12px;
  padding: 2px 4px;
  border-radius: 4px;
  color: var(--primary-dark-color);
  background: var(--secondary-color);
}