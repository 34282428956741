.about.meeting {
  padding: 50px 0;
  background: var(--secondary-light-color);
}

.about.meeting .img-container {
  justify-content: center;
  position: relative;
  margin-bottom: 50px;
}

.about.meeting .sections .img-container img {
  bottom: -50px;
  position: absolute;
  height: 60px;
  max-width: 600px;
  width: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about .sections .content {
  min-width: 350px;
}

.about.meeting .content p {
  margin-bottom: 36px !important;
}

.about.meeting .content a {
  padding: 12px 36px;
}