/* Footer */
.footer {
  color: var(--primary-dark-color);
  background: var(--secondary-color);
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer h4,
.footer p,
.footer i,
.footer .btn-link,
.footer .btn-link::before {
  color: var(--primary-dark-color);
}

.btn-link:hover {
  color: var(--primary-dark-color) !important;
}

.btn-link:hover::before {
  color: white !important;
}

.footer .btn-social {
  border-color: var(--primary-dark-color);
  transition-duration: 400ms;
  margin-right: 8px;
}

.footer .btn-social:hover {
  background: var(--primary-dark-color);
}

.footer .btn-social:hover i {
  color: white;

}